import React from 'react';
import StatusSummary from '../components/StatusSummary';
import LatestActivity from '../components/LatestActivity';

/**
 * Dashboard component serves as the landing page of the application.
 */
const Dashboard: React.FC = () => {
  return (
    <div className="min-h-screen bg-slate-900 text-white p-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-extrabold text-center mb-8">
          Deployments Overview
        </h1>
        <div className="space-y-8">
          <div className="p-6 bg-gradient-to-br from-slate-800 to-slate-900 rounded-lg shadow-xl ">
            <StatusSummary />
          </div>
          <div className="bg-gradient-to-br from-slate-800 to-slate-900 rounded-lg shadow-lg p-6">
            <LatestActivity />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
