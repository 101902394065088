import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface PrivateRouteProps {
  children: React.ReactNode;
}

/**
 * A component that restricts access to authenticated users only.
 * Redirects unauthenticated users to the login page.
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading, logout, hasRepoAccess } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem('intendedPath', location.pathname);
    }
  }, [isAuthenticated, location]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!hasRepoAccess) {
    logout(); // Optionally log out the user
    return <Navigate to="/login" state={{ noAccess: true }} replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
