import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';

/**
 * Handles the OAuth callback, processing tokens and navigating the user accordingly.
 * If an error occurs during the process, the user is redirected to the login page.
 */
const OAuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, githubIntegrationEnabled, setHasRepoAccess } = useAuth();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleCallback = async () => {
      if (!githubIntegrationEnabled) {
        setError(
          'GitHub integration is not configured. Please contact your administrator.'
        );
        toast.error(
          'Authentication failed. Please contact your administrator.'
        );
        setTimeout(() => navigate('/login', { replace: true }), 5000);
        return;
      }

      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get('token');
      const refresh_token = urlParams.get('refresh_token');
      const error = urlParams.get('error');

      if (error) {
        let errorMessage =
          'Authentication failed. Please contact your administrator.';
        if (error === 'no_repo_access') {
          errorMessage =
            'You do not have access to this application. Please contact your administrator.';
        }
        setError(errorMessage);
        toast.error(errorMessage);
        setTimeout(() => navigate('/login', { replace: true }), 5000);
        return;
      }

      if (token && refresh_token) {
        try {
          login(token, refresh_token);
          setHasRepoAccess(true);
          const intendedPath = localStorage.getItem('intendedPath') || '/';
          localStorage.removeItem('intendedPath');
          navigate(intendedPath, { replace: true });
        } catch (error: any) {
          setError('Authentication failed. Please contact your administrator.');
          toast.error(
            'Authentication failed. Please contact your administrator.'
          );
          setHasRepoAccess(false);
          setTimeout(() => navigate('/login', { replace: true }), 5000);
        }
      } else {
        setError(
          'Invalid authentication response. Please contact your administrator.'
        );
        toast.error(
          'Authentication failed. Please contact your administrator.'
        );
        setTimeout(() => navigate('/login', { replace: true }), 5000);
      }
    };

    handleCallback();
  }, [navigate, login, location, githubIntegrationEnabled, setHasRepoAccess]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 px-4">
        <div className="max-w-md w-full space-y-8 text-center">
          <h2 className="text-3xl font-extrabold text-white">
            Authentication Error
          </h2>
          <p className="text-red-500">{error}</p>
          <p className="text-gray-400">Redirecting to login page...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 px-4">
      <div className="max-w-md w-full space-y-8 text-center">
        <h2 className="text-3xl font-extrabold text-white">
          Processing login...
        </h2>
      </div>
    </div>
  );
};

export default OAuthCallback;
