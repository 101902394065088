import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Environment } from '../../types';
import { Run } from '@styled-icons/fluentui-system-regular/Run';
import { History } from '@styled-icons/material-outlined/History';
import { ExternalLinkOutline } from '@styled-icons/evaicons-outline/ExternalLinkOutline';
import { CheckCircle } from '@styled-icons/heroicons-solid/CheckCircle';

interface EnvironmentActionsProps {
  environment: Environment;
  handleRunWorkflow: () => void;
  handleViewHistory: () => void;
  repoUrl: string;
  workflowName: string;
  workflowFileName: string;
  isRunning: boolean;
  runSuccess: boolean | null;
}

const EnvironmentActions: React.FC<EnvironmentActionsProps> = ({
  environment,
  handleRunWorkflow,
  handleViewHistory,
  repoUrl,
  workflowName,
  workflowFileName,
  isRunning,
  runSuccess,
}: EnvironmentActionsProps): JSX.Element => {
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const ACTIONS = [
    {
      label: 'Run Workflow',
      icon: isRunning ? Run : runSuccess === true ? CheckCircle : Run,
      action: handleRunWorkflow,
      className: `${
        isRunning
          ? 'animate-spin text-yellow-400'
          : runSuccess === true
          ? 'text-green-400'
          : runSuccess === false
          ? 'text-red-400'
          : ''
      }`,
    },
    { label: 'Show History', icon: History, action: handleViewHistory },
    {
      label: 'View in GitHub',
      icon: ExternalLinkOutline,
      action: () => {
        if (workflowFileName) {
          const workflowUrl = `${repoUrl}/actions/workflows/${workflowFileName}`;
          window.open(workflowUrl, '_blank');
        }
      },
    },
  ];

  const handleMouseEnter = (e: React.MouseEvent, label: string) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX + rect.width / 2,
    });
    setActiveTooltip(label);
  };

  const handleMouseLeave = () => {
    setActiveTooltip(null);
  };

  return (
    <div className="flex space-x-2">
      {ACTIONS.map((action) => (
        <div key={action.label} className="relative">
          <button
            onClick={action.action}
            onMouseEnter={(e) => handleMouseEnter(e, action.label)}
            onMouseLeave={handleMouseLeave}
            className={`text-white p-2 rounded-full hover:bg-blue-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${action.className}`}
            disabled={isRunning}
          >
            <action.icon size="24" />
          </button>
        </div>
      ))}
      {activeTooltip &&
        tooltipPosition &&
        createPortal(
          <span
            className="absolute text-sm text-white bg-gray-800 rounded-md px-2 py-1 shadow-lg z-50 transition-opacity duration-150 ease-in-out"
            style={{
              top: `${tooltipPosition.top - 30}px`,
              left: `${tooltipPosition.left}px`,
              transform: 'translateX(-50%)',
              whiteSpace: 'nowrap',
            }}
          >
            {activeTooltip}
          </span>,
          document.body
        )}
    </div>
  );
};

export default EnvironmentActions;
