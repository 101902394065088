import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWorkflowData } from '../hooks/useWorkflowData';
import EnvironmentDetails from '../components/Environment/EnvironmentDetails';
import StatusSummary from '../components/StatusSummary';
import WorkflowSummary from '../components/Workflow/WorkflowSummary';
import LoadingSpinner from '../components/Shared/LoadingSpinner';
import { Environment } from '../types';
import EnvironmentHistory from '../components/Environment/EnvironmentHistory';

const Deployments: React.FC = () => {
  const { workflowName } = useParams<{ workflowName: string }>();
  const decodedWorkflowName = decodeURIComponent(workflowName || '');
  const { data, error, isLoading } = useWorkflowData(decodedWorkflowName);
  const [showingHistory, setShowingHistory] = useState<Environment | null>(
    null
  );

  const handleCloseHistory = () => {
    setShowingHistory(null);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error: {(error as Error).message}</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  if (showingHistory) {
    const historyData = data.workflowHistory.filter(
      (h) => h.environment === showingHistory
    );

    return (
      <div className="min-h-screen bg-gradient-to-b from-slate-800 to-slate-900 text-white">
        <div className="container mx-auto px-4 py-8 relative">
          <EnvironmentHistory
            history={historyData}
            toggleHistory={handleCloseHistory}
            environment={showingHistory}
            workflowName={decodedWorkflowName}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-800 to-slate-900 text-white">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-center mb-12 text-white">
          {decodedWorkflowName
            ? `${decodedWorkflowName} - Status`
            : 'Deployments Overview'}
        </h1>
        <div className="space-y-12">
          {decodedWorkflowName ? (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {Object.entries(data.latestDetails).map(([env, detail]) => (
                  <EnvironmentDetails
                    key={env}
                    environment={env as Environment}
                    workflowDetail={detail}
                    history={data.workflowHistory.filter(
                      (h) => h.environment === env
                    )}
                    workflowName={decodedWorkflowName}
                  />
                ))}
              </div>
              <WorkflowSummary workflowName={decodedWorkflowName} />
            </>
          ) : (
            <StatusSummary />
          )}
        </div>
      </div>
    </div>
  );
};

export default Deployments;
