import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import queryClient from './queryClient';
import MainLayout from './components/Layout/MainLayout';
import Deployments from './pages/Deployments';
import Dashboard from './pages/Dashboard';
import SearchResults from './pages/SearchResults';
import Login from './pages/Login';
import { checkAppStatus } from './api/axiosInstance';
import OAuthCallback from './pages/OAuthCallback';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { WorkflowProvider } from './contexts/WorkflowContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Handles the routing within the app, including protected routes and fallback components.
 */
const AppRoutes = () => {
  const { isLoading, authToken, githubIntegrationEnabled } = useAuth();

  useEffect(() => {}, [isLoading, authToken, githubIntegrationEnabled]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/oauth-callback" element={<OAuthCallback />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <MainLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Deployments />} />
          <Route path="workflow/:workflowName" element={<Deployments />} />
          <Route path="search-results" element={<SearchResults />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {!githubIntegrationEnabled && authToken && (
        <div
          className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4"
          role="alert"
        >
          <p className="font-bold">Notice</p>
          <p>
            GitHub integration is not configured. Some features may be
            unavailable.
          </p>
        </div>
      )}
    </Suspense>
  );
};

/**
 * The main app component that sets up global providers and routes.
 */
const App = () => {
  useEffect(() => {
    const fetchAppStatus = async () => {
      const status = await checkAppStatus();
    };
    fetchAppStatus();
  }, []);

  return (
    <AuthProvider>
      <WorkflowProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <AppRoutes />
            <ToastContainer position="bottom-center" autoClose={3000} />
          </Router>
        </QueryClientProvider>
      </WorkflowProvider>
    </AuthProvider>
  );
};

export default App;
