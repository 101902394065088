import React from 'react';
import { Spinner3 as SpinnerIcon } from '@styled-icons/evil';

/**
 * LoadingSpinner component renders a spinner icon.
 */
const LoadingSpinner: React.FC = () => {
  return (
    <div className="flex justify-center items-center">
      <SpinnerIcon className="animate-spin h-12 w-12 text-white" />
    </div>
  );
};

export default LoadingSpinner;
