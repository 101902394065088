import React from 'react';
import { useQuery } from 'react-query';
import axiosInstance from '../api/axiosInstance';
import LoadingSpinner from './Shared/LoadingSpinner';
import MetricsGrid from './Shared/MetricsGrid';
import { ChartPieIcon } from '@heroicons/react/24/solid';

interface DeploymentMetrics {
  total_deploys: number;
  avg_deploy_time: number;
  success_rate: number;
}

/**
 * Fetches deployment metrics from the server.
 */
const fetchMetrics = async (): Promise<DeploymentMetrics> => {
  const response = await axiosInstance.get(`/status`);
  return response.data;
};

/**
 * Formats a duration string into a more human-readable format.
 */
const formatDuration = (duration: number): string => {
  if (duration < 60) {
    return `${duration.toFixed(2)} sec`;
  } else if (duration < 3600) {
    const minutes = duration / 60;
    return `${minutes.toFixed(2)} min`;
  } else {
    const hours = duration / 3600;
    return `${hours.toFixed(2)} hr`;
  }
};

/**
 * Displays a summary of deployment metrics including total deploys,
 * average deploy time, and success rate.
 */
const StatusSummary: React.FC = () => {
  const {
    data: metrics,
    error,
    isLoading,
  } = useQuery<DeploymentMetrics, Error>('metrics', fetchMetrics, {
    retry: 3,
  });

  if (isLoading) return <LoadingSpinner />;
  if (error)
    return (
      <div className="text-red-500">Error loading metrics: {error.message}</div>
    );
  if (!metrics)
    return <div className="text-yellow-500">No metrics data available</div>;

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6 flex items-center">
        <ChartPieIcon className="w-6 h-6 mr-2" />
        Metrics
      </h2>
      <MetricsGrid
        metrics={{
          totalDeploys: metrics.total_deploys,
          avgDeployTime: formatDuration(metrics.avg_deploy_time),
          successRate: metrics.success_rate.toFixed(2),
        }}
        titles={{
          totalDeploys: 'Number of Deploys',
          avgDeployTime: 'Average Deploy Time',
          successRate: 'Success Rate',
        }}
      />
    </div>
  );
};

export default StatusSummary;
