import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { WorkflowDetail } from '../types';
import WorkflowCard from '../components/Workflow/WorkflowCard';
import { SearchAlt2 } from '@styled-icons/boxicons-regular/SearchAlt2';
import { ChevronLeft, ChevronRight } from '@styled-icons/boxicons-regular';

interface LocationState {
  searchResults: WorkflowDetail[];
}

const ITEMS_PER_PAGE = 10;

/**
 * SearchResults component displays the results of a search query.
 */
const SearchResults: React.FC = () => {
  const location = useLocation();
  const { searchResults } = location.state as LocationState;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(searchResults.length / ITEMS_PER_PAGE);

  const displayedResults = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return searchResults.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [currentPage, searchResults]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const PaginationControls = () => (
    <div className="flex justify-center items-center space-x-4 my-4">
      <button
        onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
        className="text-white transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
        title="Previous Page"
      >
        <ChevronLeft size={48} />
      </button>

      <div className="flex space-x-2">
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              currentPage === index + 1
                ? 'bg-blue-500 scale-125'
                : 'bg-slate-500 hover:bg-slate-400'
            }`}
            title={`Page ${index + 1}`}
          />
        ))}
      </div>

      <button
        onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages}
        className="text-white transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
        title="Next Page"
      >
        <ChevronRight size={48} />
      </button>

      <span className="text-slate-400">
        Page {currentPage} of {totalPages}
      </span>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-800 to-slate-900 text-white pt-20">
      <div className="container mx-auto px-10 py-8">
        <div className="bg-gradient-to-r from-slate-800 to-slate-900 rounded-lg shadow-lg mb-8 p-6">
          <h1 className="text-3xl font-bold flex items-center">
            <SearchAlt2 size="36" className="mr-4 text-blue-400" />
            Search Results
          </h1>
          <p className="text-slate-400 mt-2">
            Found {searchResults.length} result
            {searchResults.length !== 1 ? 's' : ''}
          </p>
        </div>

        {searchResults.length > 0 ? (
          <>
            {totalPages > 1 && <PaginationControls />}

            <div className="grid grid-cols-1 gap-8 mb-8">
              {displayedResults.map((result) => (
                <div
                  key={result.sha}
                  className="bg-gradient-to-r from-slate-900 to-slate-800 rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:scale-105"
                  style={{ overflow: 'visible' }}
                >
                  <WorkflowCard workflowDetail={result} showWorkflowName />
                </div>
              ))}
            </div>

            {totalPages > 1 && <PaginationControls />}
          </>
        ) : (
          <div className="bg-gradient-to-r from-slate-900 to-slate-800 rounded-lg shadow-lg p-8 text-center">
            <SearchAlt2 size="48" className="mx-auto mb-4 text-slate-500" />
            <h2 className="text-2xl font-bold mb-2">No Results Found</h2>
            <p className="text-slate-400">
              We couldn't find any workflows matching your search. Please try a
              different query.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
