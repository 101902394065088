import React from 'react';

interface MetricsGridProps {
  metrics: {
    totalDeploys: number;
    avgDeployTime: string | number;
    successRate: string | number;
  };
  titles: {
    totalDeploys: string;
    avgDeployTime: string;
    successRate: string;
  };
}

/**
 * MetricsGrid component displays deployment metrics in a grid layout.
 */
const MetricCard: React.FC<{ title: string; value: string | number }> = ({
  title,
  value,
}) => (
  <div className="bg-gradient-to-br from-slate-700 to-slate-800 rounded-lg p-6 transition-all duration-300 ease-in-out transform hover:scale-105 shadow-lg hover:shadow-xl">
    <h3 className="text-sm text-indigo-100 font-medium mb-2">{title}</h3>
    <p className="text-4xl font-bold text-white">{value}</p>
  </div>
);

const MetricsGrid: React.FC<MetricsGridProps> = ({ metrics, titles }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <MetricCard title={titles.totalDeploys} value={metrics.totalDeploys} />
      <MetricCard title={titles.avgDeployTime} value={metrics.avgDeployTime} />
      <MetricCard
        title={titles.successRate}
        value={`${metrics.successRate}%`}
      />
    </div>
  );
};

export default MetricsGrid;
