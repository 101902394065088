import React from 'react';
import { Github } from '@styled-icons/boxicons-logos/Github';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 * The Login component provides a GitHub OAuth login interface.
 * If the user is already authenticated, they are redirected to the homepage.
 */
const Login: React.FC = () => {
  const { authToken, initiateLogin, githubIntegrationEnabled, hasRepoAccess } =
    useAuth();

  // Redirect to the homepage if the user is already authenticated.
  if (authToken) {
    return <Navigate to="/" replace />;
  }

  // Display a message if GitHub integration is not enabled.
  if (!githubIntegrationEnabled) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 px-4">
        <div className="max-w-md w-full space-y-8 text-center">
          <h2 className="text-3xl font-extrabold text-white">
            GitHub Integration Not Configured
          </h2>
          <p className="text-gray-400">
            Something went wrong. Please contact the administrator.
          </p>
        </div>
      </div>
    );
  }

  /**
   * Handles the login process by initiating the GitHub OAuth flow.
   * Displays an error toast if the login process fails.
   */
  const handleLogin = async () => {
    try {
      await initiateLogin();
    } catch (error) {
      toast.error('Failed to initiate login. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 px-4">
      <div className="max-w-md w-full space-y-4">
        <div className="flex flex-col items-center">
          <img
            className="w-72 h-auto mb-2 -mt-64"
            src="/savi-white-logo.png"
            alt="Savi Logo"
          />
          <h2 className="text-3xl font-extrabold text-white">API Dashboard</h2>
          <p className="mt-2 text-center text-sm text-gray-400">
            Please sign in to access your account
          </p>
        </div>
        <div className="mt-6">
          <button
            onClick={handleLogin}
            className="group relative w-full flex items-center justify-center px-6 py-3 text-lg font-medium text-white rounded-full overflow-hidden bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300 ease-in-out transform hover:scale-105 shadow-lg hover:shadow-xl"
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <Github className="h-6 w-6 text-indigo-300 group-hover:text-indigo-200 transition-colors duration-300 ease-in-out" />
            </span>
            <span className="ml-4">Sign in with GitHub</span>
          </button>
        </div>

        {!hasRepoAccess && (
          <div className="mt-4 text-center text-sm text-red-500">
            You do not have access to the required repository. Please contact
            your administrator.
          </div>
        )}

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-700"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-gray-900 text-gray-400">
                Powered by GitHub OAuth
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
