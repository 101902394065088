import React from 'react';
import { useLatestActivity } from '../hooks/useLatestActivity';
import LoadingSpinner from './Shared/LoadingSpinner';
import StatusIcon from './Shared/StatusIcon';
import { parseDate, formatDateDistance } from '../utils/dateUtils';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ClockIcon,
} from '@heroicons/react/24/solid';
import { ChevronLeft, ChevronRight } from '@styled-icons/boxicons-regular';

const LatestActivity: React.FC = () => {
  const {
    paginatedData: activities,
    isLoading,
    isError,
    page,
    setPage,
    totalPages,
  } = useLatestActivity();

  React.useEffect(() => {}, [activities]);

  if (isLoading) return <LoadingSpinner />;
  if (isError)
    return <div className="text-red-500">Error loading latest activities.</div>;
  if (activities.length === 0)
    return (
      <div className="text-yellow-500">
        No recent deployment activities found.
      </div>
    );

  const PaginationIndicators = () => (
    <div className="flex justify-between items-center my-4">
      <div className="w-24"></div> {/* Spacer for alignment */}
      <div className="flex space-x-2">
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => setPage(index + 1)}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              page === index + 1
                ? 'bg-blue-500 scale-110'
                : 'bg-slate-500 hover:bg-slate-400'
            }`}
            title={`Page ${index + 1}`}
          />
        ))}
      </div>
      <div className="w-24 text-right">
        <span className="text-slate-400 text-sm">
          Page {page} of {totalPages}
        </span>
      </div>
    </div>
  );

  return (
    <div className="p-6 bg-gradient-to-br from-slate-800 to-slate-900 rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-6 text-white flex items-center">
        <ClockIcon className="w-6 h-6 mr-2" />
        Latest Activity
      </h2>
      {totalPages > 1 && <PaginationIndicators />}
      <div className="relative">
        {totalPages > 1 && (
          <>
            <button
              onClick={() => setPage(Math.max(1, page - 1))}
              disabled={page === 1}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 -ml-12 text-white transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed hover:text-blue-400 focus:outline-none"
              title="Previous Page"
            >
              <ChevronLeft
                size={72}
                className="hover:scale-110 transition-transform duration-200"
              />
            </button>
            <button
              onClick={() => setPage(Math.min(totalPages, page + 1))}
              disabled={page === totalPages}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 -mr-12 text-white transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed hover:text-blue-400 focus:outline-none"
              title="Next Page"
            >
              <ChevronRight
                size={72}
                className="hover:scale-110 transition-transform duration-200"
              />
            </button>
          </>
        )}
        <table className="w-full text-white">
          <thead>
            <tr className="bg-gradient-to-r from-slate-700 to-slate-800">
              <th className="py-3 px-4 text-center font-semibold">API</th>
              <th className="py-3 px-4 text-center font-semibold">Commit</th>
              <th className="py-3 px-4 text-center font-semibold">Status</th>
              <th className="py-3 px-4 text-center font-semibold">Duration</th>
              <th className="py-3 px-4 text-center font-semibold">
                Deployed At
              </th>
              <th className="py-3 px-4 text-center font-semibold">
                Avg Change
              </th>
            </tr>
          </thead>
          <tbody>
            {activities.map((activity, index) => (
              <tr
                key={`${activity.environment}-${activity.api}-${index}`}
                className="border-b border-slate-700 hover:bg-slate-900 transition-colors duration-200"
              >
                <td className="py-3 px-4">
                  {`${activity.environment} ${activity.api
                    .replace(/^Deployment for \w+ /, '')
                    .replace(/ \(k8s\)$/, '')}`}
                </td>
                <td className="py-3 px-4 text-center">
                  <span className="truncate max-w-xs inline-block">
                    {activity.commit}
                  </span>
                </td>
                <td className="py-3 px-4">
                  <div className="flex items-center justify-center">
                    <StatusIcon status={activity.status} />
                    <span className="truncate max-w-xs ml-2">
                      {activity.status}
                    </span>
                  </div>
                </td>
                <td className="py-3 px-4 text-center">
                  <span className="truncate max-w-xs inline-block">
                    {activity.duration}
                  </span>
                </td>
                <td className="py-3 px-4 text-center">
                  <span className="truncate max-w-xs inline-block">
                    {formatDateDistance(parseDate(activity.deployed_at))}
                  </span>
                </td>
                <td className="py-3 px-4">
                  <div className="flex items-center justify-center">
                    {activity.avg_change > 0 ? (
                      <ChevronUpIcon className="w-5 h-5 text-red-500" />
                    ) : (
                      <ChevronDownIcon className="w-5 h-5 text-green-500" />
                    )}
                    <span
                      className={
                        activity.avg_change > 0
                          ? 'text-red-500'
                          : 'text-green-500'
                      }
                    >
                      {Math.abs(activity.avg_change).toFixed(2)}%
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {totalPages > 1 && <PaginationIndicators />}
    </div>
  );
};

export default LatestActivity;
