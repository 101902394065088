import { useQuery } from 'react-query';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';

interface Workflow {
  name: string;
  trimmedName: string;
  environments: {
    name: string;
    avgDeployTime: string;
    lastDeployedTime: string;
    avgDeployDuration: string;
  }[];
}

export const useWorkflows = () => {
  const { checkTokenExpiration, refreshAccessToken } = useAuth();

  const fetchWorkflows = async (): Promise<Workflow[]> => {
    try {
      if (checkTokenExpiration()) {
        await refreshAccessToken();
      }

      const response = await axiosInstance.get('/workflows');
      const workflowData: Workflow[] = response.data.workflows || [];

      // Sort workflows alphabetically by name
      workflowData.sort((a, b) => a.name.localeCompare(b.name));

      // Ensure all workflows have all environments
      const allEnvironments = ['Dev', 'Staging', 'Prod'];
      workflowData.forEach((workflow) => {
        allEnvironments.forEach((env) => {
          if (!workflow.environments.some((e) => e.name === env)) {
            workflow.environments.push({
              name: env,
              avgDeployTime: 'N/A',
              lastDeployedTime: 'N/A',
              avgDeployDuration: 'N/A',
            });
          } else {
            const environment = workflow.environments.find(
              (e) => e.name === env
            );
            if (environment && environment.lastDeployedTime !== 'N/A') {
              const date = new Date(environment.lastDeployedTime);
              let formattedTime = formatDistanceToNow(date, {
                addSuffix: true,
              });

              // Abbreviate time units according to the specified format
              formattedTime = formattedTime
                .replace(/ ago$/, '')
                .replace(/about /, '')
                .replace(/less than a minute/, '<1m')
                .replace(/about an hour/, '1h')
                .replace(/(\d+) hours?/, '$1h')
                .replace(/(\d+) minutes?/, '$1m')
                .replace(/(\d+) seconds?/, '$1s')
                .replace(/(\d+) days?/, '$1d')
                .replace(/(\d+) weeks?/, '$1w')
                .replace(/(\d+) months?/, '$1mo')
                .replace(/(\d+) years?/, '$1y')
                .replace(/a minute/, '1m')
                .replace(/an hour/, '1h')
                .replace(/a day/, '1d')
                .replace(/a week/, '1w')
                .replace(/a month/, '1mo')
                .replace(/a year/, '1yr');

              environment.lastDeployedTime = `${formattedTime} ago`;
            }
          }
        });
        // Sort environments
        workflow.environments.sort(
          (a, b) =>
            allEnvironments.indexOf(a.name) - allEnvironments.indexOf(b.name)
        );
      });

      return workflowData;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 401:
            throw new Error('Authentication failed. Please log in again.');
          case 403:
            throw new Error('Access forbidden. Please check your permissions.');
          case 404:
            throw new Error('Workflows not found.');
          default:
            throw new Error(`Failed to fetch workflows: ${error.message}`);
        }
      } else {
        throw new Error('An unexpected error occurred. Please try again.');
      }
    }
  };

  return useQuery<Workflow[], Error>('workflows', fetchWorkflows, {
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchInterval: 5 * 60 * 1000, // 5 minutes
  });
};
