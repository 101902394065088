// src/components/WorkflowSummary.tsx
import React from 'react';
import { Environment, WorkflowDetail } from '../../types';
import { useWorkflowData } from '../../hooks/useWorkflowData';
import LoadingSpinner from '../Shared/LoadingSpinner';
import MetricsGrid from '../Shared/MetricsGrid';

interface WorkflowSummaryProps {
  workflowName: string;
}

/**
 * Formats the duration from seconds to a human-readable string.
 */
const formatDuration = (duration: number): string => {
  if (duration < 60) {
    return `${duration.toFixed(2)} sec`;
  } else if (duration < 3600) {
    const minutes = duration / 60;
    return `${minutes.toFixed(2)} min`;
  } else {
    const hours = duration / 3600;
    return `${hours.toFixed(2)} hr`;
  }
};

/**
 * Aggregates metrics from workflow details and history.
 */
const aggregateMetrics = (
  details: Record<Environment, WorkflowDetail | null>,
  history: WorkflowDetail[]
) => {
  let totalDeploys = history.length;
  let totalTime = 0;
  let successCount = 0;

  history.forEach((detail) => {
    if (detail) {
      const duration = parseFloat(String(detail.duration ?? '0'));
      totalTime += duration;
      if (detail.conclusion === 'success') successCount += 1;
    }
  });

  const avgDeployTime = totalDeploys ? totalTime / totalDeploys : 0;
  const successRate = totalDeploys
    ? ((successCount / totalDeploys) * 100).toFixed(2)
    : 'N/A';

  return {
    totalDeploys,
    avgDeployTime: formatDuration(avgDeployTime),
    successRate,
  };
};

/**
 * WorkflowSummary component displays a summary of workflow metrics.
 */
const WorkflowSummary: React.FC<WorkflowSummaryProps> = ({ workflowName }) => {
  const { data, isLoading, isError } = useWorkflowData(workflowName);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || !data) {
    return <div>Error loading workflow data.</div>;
  }

  const metrics = aggregateMetrics(data.latestDetails, data.workflowHistory);

  return (
    <div className="">
      <h2 className="text-2xl font-bold mb-6">Workflow Metrics</h2>
      <MetricsGrid
        metrics={metrics}
        titles={{
          totalDeploys: 'Number of Deploys',
          avgDeployTime: 'Average Deploy Time',
          successRate: 'Success Rate',
        }}
      />
    </div>
  );
};

export default WorkflowSummary;
