import React, { useState } from 'react';
import { Dashboard } from '@styled-icons/material-rounded/Dashboard';
import { Rocket } from '@styled-icons/ionicons-outline/Rocket';
import { Github } from '@styled-icons/boxicons-logos/Github';
import { Logout } from '@styled-icons/material-outlined/Logout';
import { useNavigate } from 'react-router-dom';
import { useWorkflows } from '../../hooks/useWorkflows';
import { useRepoURL } from '../../hooks/useRepoURL';
import { useAuth } from '../../contexts/AuthContext';
import Tooltip from '../Tooltip';
import WorkflowList from './WorkflowList';
import ConfirmationModal from '../ConfirmationModal';
import { useWorkflow } from '../../contexts/WorkflowContext';
import logo from '../../assets/images/savi-logo-64.png';

interface SidebarProps {
  showWorkflows: boolean;
  setShowWorkflows: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Sidebar component provides navigation buttons for the dashboard, deployments, settings, and GitHub.
 */
const Sidebar: React.FC<SidebarProps> = ({
  showWorkflows,
  setShowWorkflows,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { data: workflows, error: workflowsError } = useWorkflows();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { data: repoURL, error: repoURLError } = useRepoURL();
  const { selectWorkflow } = useWorkflow();

  /**
   * Handles navigation to the home page.
   */
  const handleHomeClick = () => {
    navigate('/');
  };

  /**
   * Handles selection of a workflow and navigation to the workflow page.
   */
  const handleWorkflowSelect = (trimmedName: string) => {
    const encodedWorkflowName = encodeURIComponent(trimmedName);
    selectWorkflow(encodedWorkflowName, trimmedName);
    navigate(`/workflow/${encodedWorkflowName}`);
  };

  /**
   * Handles click on the GitHub button and opens the repository URL in a new tab.
   */
  const handleGithubClick = () => {
    if (repoURL) {
      window.open(repoURL, '_blank');
    } else if (repoURLError instanceof Error) {
      alert(repoURLError.message);
    }
  };

  /**
   * Handles the logout action and navigates to the login page.
   */
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  /**
   * Toggles the visibility of the workflows list.
   */
  const toggleWorkflows = () => {
    setShowWorkflows((prev) => !prev);
  };

  const SIDEBAR_BUTTONS = [
    { label: 'Dashboard', icon: Dashboard, action: handleHomeClick },
    { label: 'Deployments', icon: Rocket, action: toggleWorkflows },
  ];

  return (
    <div className="flex h-full">
      <div className="bg-gradient-to-b from-slate-800 to-slate-900 flex flex-col items-center justify-start w-18 h-full shadow-lg">
        <img src={logo} alt="Logo" className="w-8 h-8 mt-4" />{' '}
        <div className="flex flex-col space-y-8 mt-20">
          {SIDEBAR_BUTTONS.map((button) => (
            <Tooltip key={button.label} text={button.label}>
              <div
                onClick={button.action}
                className="flex items-center justify-center w-full cursor-pointer p-2 rounded-full transition-all hover:bg-blue-500 hover:text-white hover:scale-110"
              >
                <button.icon size="28" />
              </div>
            </Tooltip>
          ))}
        </div>
        <div className="mt-auto mb-4 space-y-8">
          <Tooltip text="Logout">
            <div
              onClick={() => setShowLogoutModal(true)}
              className="flex items-center justify-center w-full cursor-pointer p-2 rounded-full transition-all hover:bg-blue-500 hover:text-white hover:scale-110"
            >
              <Logout size="28" />
            </div>
          </Tooltip>
          <Tooltip text="GitHub">
            <div
              onClick={handleGithubClick}
              className="flex items-center justify-center w-full cursor-pointer p-2 rounded-full transition-all hover:bg-blue-500 hover:text-white hover:scale-110"
            >
              <Github size="28" />
            </div>
          </Tooltip>
        </div>
      </div>
      {workflowsError && (
        <div className="text-red-500 p-4">{workflowsError.message}</div>
      )}
      <WorkflowList
        workflows={workflows || []}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleWorkflowSelect={handleWorkflowSelect}
        showWorkflows={showWorkflows}
      />
      {showLogoutModal && (
        <ConfirmationModal
          message="Are you sure you want to log out?"
          onConfirm={handleLogout}
          onCancel={() => setShowLogoutModal(false)}
        />
      )}
    </div>
  );
};

export default Sidebar;
