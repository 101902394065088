import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../Header/Navbar';
import Sidebar from './Sidebar';

interface MainLayoutProps {
  children?: React.ReactNode;
}

/**
 * MainLayout component with Navbar and Sidebar.
 * Controls the visibility of workflows in the Sidebar based on screen size.
 */
const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [showWorkflows, setShowWorkflows] = useState(false);

  /**
   * Handles the resize event to toggle the visibility of the workflow list
   * based on the screen width.
   */
  useEffect(() => {
    const handleResize = () => {
      setShowWorkflows(window.innerWidth >= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        showWorkflows={showWorkflows}
        setShowWorkflows={setShowWorkflows}
      />
      <div className="flex flex-col flex-1 overflow-hidden">
        <Navbar />
        <div
          className={`flex-1 overflow-auto p-4 md:p-8 lg:p-12 transition-all duration-300 ${
            showWorkflows ? 'ml-78' : 'ml-18'
          }`}
        >
          {children}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
