import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import axiosInstance, {
  setupAxiosInterceptors,
  checkAppStatus,
} from '../api/axiosInstance';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

interface AuthContextType {
  authToken: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  isRefreshing: boolean;
  initiateLogin: () => Promise<void>;
  login: (token: string, refreshToken: string) => void;
  logout: () => void;
  refreshAccessToken: () => Promise<{ token: string; refresh_token: string }>;
  checkTokenExpiration: () => boolean;
  githubIntegrationEnabled: boolean;
  hasRepoAccess: boolean;
  setHasRepoAccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

/**
 * Custom hook to access authentication context.
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

/**
 * Provides authentication context and manages user authentication state.
 */
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [githubIntegrationEnabled, setGithubIntegrationEnabled] =
    useState(true);
  const [hasRepoAccess, setHasRepoAccess] = useState(true);

  const TOKEN_REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes
  const TOKEN_EXPIRY_BUFFER = 5 * 60; // 5 minutes in seconds

  const refreshSubscribers = useRef<Array<(token: string) => void>>([]);
  const isRefreshingRef = useRef<boolean>(isRefreshing);

  useEffect(() => {
    const storedAuthToken = localStorage.getItem('authToken');
    const storedRefreshToken = localStorage.getItem('refreshToken');
    if (storedAuthToken && storedRefreshToken) {
      setAuthToken(storedAuthToken);
      setIsAuthenticated(true);
      axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${storedAuthToken}`;
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (authToken) {
      axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${authToken}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  }, [authToken]);

  useEffect(() => {
    const fetchAppState = async () => {
      const appState = await checkAppStatus();
      setGithubIntegrationEnabled(appState.githubIntegrationEnabled);
    };

    fetchAppState();
  }, []);

  /**
   * Initiates the login process by redirecting to the login URL.
   */
  const initiateLogin = useCallback(async (): Promise<void> => {
    const loginUrl = `${axiosInstance.defaults.baseURL}/login`;
    window.location.href = loginUrl;
  }, []);

  /**
   * Logs in the user by storing tokens and setting authentication state.
   */
  const login = useCallback((token: string, refreshToken: string) => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('refreshToken', refreshToken);
    setAuthToken(token);
    setIsAuthenticated(true);
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }, []);

  /**
   * Logs out the user and clears authentication data.
   */
  const logout = useCallback(async () => {
    try {
      await axiosInstance.post('/logout');
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      setAuthToken(null);
      setIsAuthenticated(false);
      delete axiosInstance.defaults.headers.common['Authorization'];
    } catch (error) {
      // Handle logout error if necessary
    }
  }, []);

  /**
   * Refreshes the access token using the stored refresh token.
   */
  const refreshAccessToken = useCallback(async (): Promise<{
    token: string;
    refresh_token: string;
  }> => {
    setIsRefreshing(true);
    isRefreshingRef.current = true;
    const storedRefreshToken = localStorage.getItem('refreshToken');
    if (!storedRefreshToken) {
      setIsRefreshing(false);
      isRefreshingRef.current = false;
      logout();
      return Promise.reject('No refresh token available');
    }
    try {
      const response = await axiosInstance.post('/refresh', {
        refresh_token: storedRefreshToken,
      });
      const { token, refresh_token } = response.data;
      login(token, refresh_token);
      setIsRefreshing(false);
      isRefreshingRef.current = false;
      return { token, refresh_token };
    } catch (error: unknown) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data?.error === 're_auth_required'
      ) {
        initiateLogin();
      } else {
        logout();
      }
      setIsRefreshing(false);
      isRefreshingRef.current = false;
      return Promise.reject(error);
    }
  }, [login, logout, initiateLogin]);

  /**
   * Adds a failed request to the queue and handles refreshing the token.
   */
  const refreshTokenAndRetry = useCallback(
    async (failedRequest: any) => {
      try {
        if (!isRefreshingRef.current) {
          setIsRefreshing(true);
          isRefreshingRef.current = true;
          const { token } = await refreshAccessToken();
          setIsRefreshing(false);
          isRefreshingRef.current = false;
          refreshSubscribers.current.forEach((callback) => callback(token));
          refreshSubscribers.current = [];
        }
        return axiosInstance(failedRequest);
      } catch (error) {
        refreshSubscribers.current = [];
        setIsRefreshing(false);
        isRefreshingRef.current = false;
        throw error;
      }
    },
    [refreshAccessToken]
  );

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          if (!isRefreshingRef.current) {
            return refreshTokenAndRetry(originalRequest);
          } else {
            return new Promise((resolve) => {
              refreshSubscribers.current.push((token: string) => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                resolve(axiosInstance(originalRequest));
              });
            });
          }
        }
        return Promise.reject(error);
      }
    );
  }, [refreshTokenAndRetry]);

  /**
   * Checks if the current token is expired or will expire soon.
   */
  const checkTokenExpiration = useCallback(() => {
    const token = localStorage.getItem('authToken');
    if (!token) return true;
    try {
      const decodedToken = jwtDecode<{ exp: number }>(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime + TOKEN_EXPIRY_BUFFER;
    } catch {
      return true;
    }
  }, [TOKEN_EXPIRY_BUFFER]);

  useEffect(() => {
    const tokenRefreshInterval = setInterval(async () => {
      if (authToken && checkTokenExpiration()) {
        try {
          const { token } = await refreshAccessToken();
          setAuthToken(token);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 401) {
              initiateLogin();
            } else if (error.response?.status === 403) {
              logout();
            }
          }
        }
      }
    }, TOKEN_REFRESH_INTERVAL);

    return () => clearInterval(tokenRefreshInterval);
  }, [
    authToken,
    checkTokenExpiration,
    refreshAccessToken,
    TOKEN_REFRESH_INTERVAL,
    initiateLogin,
    logout,
  ]);

  useEffect(() => {
    setupAxiosInterceptors(refreshAccessToken, logout, setHasRepoAccess);
  }, [refreshAccessToken, logout, setHasRepoAccess]);

  const contextValue = useMemo(
    () => ({
      authToken,
      isAuthenticated,
      isLoading,
      isRefreshing,
      initiateLogin,
      login,
      logout,
      refreshAccessToken,
      checkTokenExpiration,
      githubIntegrationEnabled,
      hasRepoAccess,
      setHasRepoAccess,
    }),
    [
      authToken,
      isAuthenticated,
      isLoading,
      isRefreshing,
      initiateLogin,
      login,
      logout,
      refreshAccessToken,
      checkTokenExpiration,
      githubIntegrationEnabled,
      hasRepoAccess,
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
