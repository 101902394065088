import React, { useState } from 'react';
import { SearchAlt2 } from '@styled-icons/boxicons-regular/SearchAlt2';
import { UserCircle } from '@styled-icons/boxicons-solid/UserCircle';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../../api/axiosInstance';
import { useUserDetails } from '../../hooks/useUserDetails';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';

const Navbar: React.FC = () => {
  const [commitSearch, setCommitSearch] = useState('');
  const [searchError, setSearchError] = useState<string | null>(null);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const navigate = useNavigate();
  const { data: userDetails, error: userError, isLoading } = useUserDetails();
  const { checkTokenExpiration, refreshAccessToken } = useAuth();

  const searchCommit = async () => {
    if (!commitSearch.trim()) {
      setSearchError('Please enter a commit to search.');
      setTimeout(() => setSearchError(null), 3000);
      return;
    }

    setIsLoadingSearch(true);
    try {
      if (checkTokenExpiration()) {
        await refreshAccessToken();
      }
      const response = await axiosInstance.get(
        `/search?commit=${commitSearch.trim()}`
      );
      navigate(`/search-results`, { state: { searchResults: response.data } });
      setCommitSearch('');
      setSearchError(null);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          setSearchError('Authentication failed. Please log in again.');
        } else if (error.response?.status === 404) {
          setSearchError('Commit could not be found.');
        } else {
          setSearchError(
            'An error occurred while searching. Please try again.'
          );
        }
      } else {
        setSearchError('An unexpected error occurred. Please try again.');
      }
      setCommitSearch('');
      setTimeout(() => {
        setSearchError(null);
      }, 3000);
    } finally {
      setIsLoadingSearch(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchCommit();
    }
  };

  const handleSearchIconClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    searchCommit();
  };

  return (
    <nav className="bg-gradient-to-r from-slate-800 to-slate-900 p-4 shadow-lg sticky top-0 z-40 w-full">
      <div className="flex flex-col sm:flex-row items-center justify-between max-w-7xl mx-auto">
        <div className="w-full sm:w-auto mb-4 sm:mb-0">
          <div className="relative w-full sm:w-96">
            <input
              type="text"
              value={commitSearch}
              onChange={(e) => setCommitSearch(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Search commits"
              className={`p-2 pl-4 pr-10 border rounded-full bg-slate-700 text-white transition-all duration-300 w-full focus:ring-2 focus:ring-blue-500 ${
                searchError ? 'border-red-500' : 'border-slate-600'
              }`}
            />
            <button
              onClick={handleSearchIconClick}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white p-2 focus:outline-none hover:text-blue-400 transition-colors duration-200"
            >
              {isLoadingSearch ? <LoadingSpinner /> : <SearchAlt2 size="20" />}
            </button>
            {searchError && (
              <div className="absolute left-0 mt-2 w-full bg-red-500 text-white text-center py-1 rounded-md text-xs">
                {searchError}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center bg-slate-800 rounded-full px-4 py-2">
          {isLoading ? (
            <LoadingSpinner />
          ) : userError ? (
            <span className="text-red-500">Error loading user details</span>
          ) : userDetails ? (
            <>
              {userDetails.avatarUrl ? (
                <img
                  src={userDetails.avatarUrl}
                  alt={userDetails.username}
                  className="w-8 h-8 rounded-full mr-2 border-2 border-blue-500"
                />
              ) : (
                <UserCircle size="32" className="text-blue-500 mr-2" />
              )}
              <span className="text-white font-semibold">
                {userDetails.username}
              </span>
            </>
          ) : (
            <span className="text-white">User not found</span>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
