import React, { createContext, useState, useContext, ReactNode } from 'react';

interface WorkflowContextType {
  selectedWorkflow: {
    fullName: string | null; // Stores the complete workflow name
    trimmedName: string | null; // Stores the environment-independent workflow name
  };
  selectWorkflow: (fullName: string, trimmedName: string) => void;
}

const WorkflowContext = createContext<WorkflowContextType | undefined>(
  undefined
);

/**
 * Custom hook to use the WorkflowContext.
 * Throws an error if used outside of WorkflowProvider.
 */
export const useWorkflow = () => {
  const context = useContext(WorkflowContext);
  if (!context) {
    throw new Error('useWorkflow must be used within a WorkflowProvider');
  }
  return context;
};

/**
 * WorkflowProvider component that provides workflow context to its children.
 */
export const WorkflowProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedWorkflow, setSelectedWorkflow] = useState<{
    fullName: string | null;
    trimmedName: string | null;
  }>({
    fullName: null,
    trimmedName: null,
  });

  const selectWorkflow = (fullName: string, trimmedName: string) => {
    setSelectedWorkflow({ fullName, trimmedName });
  };

  return (
    <WorkflowContext.Provider value={{ selectedWorkflow, selectWorkflow }}>
      {children}
    </WorkflowContext.Provider>
  );
};
