import React from 'react';
import { ExclamationCircle } from '@styled-icons/heroicons-solid/ExclamationCircle';

interface ConfirmationModalProps {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

/**
 * ConfirmationModal component provides a modal dialog for confirming actions.
 */
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 px-4">
      <div className="bg-gray-900 p-8 rounded-lg shadow-lg text-white max-w-md w-full space-y-8">
        <div>
          <div className="flex justify-center">
            <ExclamationCircle className="h-12 w-12 text-blue-400" />
          </div>
          <h2 className="mt-6 text-center text-2xl font-extrabold text-white">
            Confirmation
          </h2>
          <p className="mt-2 text-center text-sm text-gray-400">{message}</p>
        </div>
        <div className="mt-8 flex space-x-8">
          <button
            onClick={onConfirm}
            className="flex-1 flex items-center justify-center px-6 py-3 text-lg font-medium text-white rounded-full overflow-hidden bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300 ease-in-out transform hover:scale-105 shadow-lg hover:shadow-xl"
          >
            Yes
          </button>
          <button
            onClick={onCancel}
            className="flex-1 flex items-center justify-center px-6 py-3 text-lg font-medium text-white rounded-full overflow-hidden bg-gradient-to-r from-gray-600 to-gray-700 hover:from-gray-700 hover:to-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-300 ease-in-out transform hover:scale-105 shadow-lg hover:shadow-xl"
          >
            Cancel
          </button>
        </div>
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
