import { formatDistanceToNow } from 'date-fns';

/**
 * Attempts to parse a date string into a Date object.
 */
export const parseDate = (dateString: string): Date | null => {
  const timestamp = Date.parse(dateString);
  return isNaN(timestamp) ? null : new Date(timestamp);
};

/**
 * Formats a given date to a human-readable distance from now.
 */
export const formatDateDistance = (date: Date | null): string => {
  if (!date) return 'Invalid date';
  try {
    return formatDistanceToNow(date, { addSuffix: true });
  } catch (error) {
    return 'Unknown';
  }
};
