import axios, { AxiosError } from 'axios';

/**
 * Extends the Axios InternalAxiosRequestConfig to include a retry flag.
 */
declare module 'axios' {
  export interface InternalAxiosRequestConfig {
    _retry?: boolean;
  }
}

/**
 * Base URL for API requests, taken from environment variables or defaulting to "/api".
 */
const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8081/api' // Use port 8081 for API in development
    : '/api';
/**
 * Creates an Axios instance configured with the base API URL.
 */
const axiosInstance = axios.create({
  baseURL: API_URL,
});

/**
 * Interceptor to handle request configuration before the request is sent.
 * Removes "undefined" from the start of URLs if present.
 */
axiosInstance.interceptors.request.use(
  (config) => {
    if (config.url?.startsWith('undefined')) {
      config.url = config.url.replace('undefined', '');
    }
    return config;
  },
  (error) => Promise.reject(error)
);

/**
 * Sets up interceptors for handling token refresh logic when receiving a 401 error.
 * Attaches the refreshed token to the failed request and retries it.
 * Also handles 403 Forbidden errors by logging the user out and setting repository access to false.
 */
const setupAxiosInterceptors = (
  refreshAccessToken: () => Promise<{ token: string; refresh_token: string }>,
  logout: () => void,
  setHasRepoAccess: (value: boolean) => void
) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config;

      if (
        (error.response?.status === 401 || error.response?.status === 403) &&
        originalRequest &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          const { token, refresh_token } = await refreshAccessToken();
          localStorage.setItem('authToken', token);
          localStorage.setItem('refreshToken', refresh_token);
          axiosInstance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${token}`;

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          logout();
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
export { setupAxiosInterceptors };

export const checkAppStatus = async () => {
  try {
    const response = await axiosInstance.get('/app-status');
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      //TODO: Handle error
    }
    return { githubIntegrationEnabled: false };
  }
};
