import React, { useState } from 'react';
import { SortAlphaDown, SortAlphaUp } from '@styled-icons/fa-solid';
import { SearchAlt2 } from '@styled-icons/boxicons-regular/SearchAlt2';
import { Clock } from '@styled-icons/feather/Clock';
import { Activity } from '@styled-icons/feather/Activity';

interface Workflow {
  name: string;
  trimmedName: string;
  environments: {
    name: string;
    avgDeployTime: string;
    lastDeployedTime: string;
    avgDeployDuration: string;
  }[];
}

interface WorkflowListProps {
  workflows: Workflow[];
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleWorkflowSelect: (trimmedName: string, name: string) => void;
  showWorkflows: boolean;
}

const WorkflowList: React.FC<WorkflowListProps> = ({
  workflows,
  searchTerm,
  setSearchTerm,
  handleWorkflowSelect,
  showWorkflows,
}) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const filteredWorkflows = workflows.filter((workflow) =>
    workflow.trimmedName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedWorkflows = filteredWorkflows.sort((a, b) => {
    return sortOrder === 'asc'
      ? a.trimmedName.localeCompare(b.trimmedName)
      : b.trimmedName.localeCompare(a.trimmedName);
  });

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const renderEnvironmentInfo = (env: Workflow['environments'][0]) => {
    if (env.lastDeployedTime === 'N/A') return null;

    return (
      <div
        key={env.name}
        className="mt-1 text-xs flex justify-between bg-gradient-to-br from-slate-800 to-slate-900 border-slate-700 rounded p-1.5"
      >
        <div className="font-medium text-slate-300 self-center">{env.name}</div>
        <div className="flex flex-col items-end">
          <div className="flex items-center mb-1">
            <Activity size="12" className="mr-1 text-green-400" />
            <span className="text-slate-400 text-[10px] mr-1">Avg Deploy:</span>
            <span className="text-slate-200 text-xs">
              {env.avgDeployDuration}
            </span>
          </div>
          <div className="flex items-center">
            <Clock size="12" className="mr-1 text-blue-400" />
            <span className="text-slate-400 text-[10px] mr-1">
              Last Deploy:
            </span>
            <span className="text-slate-200 text-xs">
              {env.lastDeployedTime}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`transition-all duration-200 ${
        showWorkflows ? 'w-72' : 'w-0'
      } h-full bg-slate-900 border-r border-slate-700 overflow-hidden flex flex-col`}
    >
      <div className={`flex flex-col h-full ${showWorkflows ? '' : 'hidden'}`}>
        <div className="flex-shrink-0 sticky top-0 z-10 bg-slate-900 p-4 border-b border-slate-700">
          <div className="flex items-center justify-between text-white mb-4">
            <div className="flex-grow text-center">
              <span className="text-lg font-semibold">API Deployments</span>
            </div>
            <button
              onClick={toggleSortOrder}
              className="text-slate-400 hover:text-white focus:outline-none transition-colors duration-200 ml-2"
              title={`Sort ${sortOrder === 'asc' ? 'descending' : 'ascending'}`}
            >
              {sortOrder === 'asc' ? (
                <SortAlphaDown size="18" />
              ) : (
                <SortAlphaUp size="18" />
              )}
            </button>
          </div>
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search APIs"
              className="w-full p-2 pl-8 pr-4 rounded-md bg-slate-800 text-sm text-white placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
            />
            <SearchAlt2
              size="18"
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-slate-400"
            />
          </div>
        </div>
        <ul className="flex-grow overflow-y-auto py-2 scrollbar-thin scrollbar-thumb-slate-600 scrollbar-track-slate-800">
          {sortedWorkflows.map((workflow) => (
            <li
              key={workflow.trimmedName}
              onClick={() =>
                handleWorkflowSelect(workflow.trimmedName, workflow.name)
              }
              className="cursor-pointer px-4 py-2 hover:bg-slate-800 transition-colors duration-200 border-b border-slate-700 last:border-b-0"
            >
              <div className="font-medium text-slate-200 text-md mb-1 text-center">
                {workflow.trimmedName}
              </div>
              <div className="space-y-1">
                {workflow.environments
                  .filter((env) => env.lastDeployedTime !== 'N/A')
                  .map(renderEnvironmentInfo)}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WorkflowList;
