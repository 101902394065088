import React, { useState } from 'react';
import { WorkflowDetail } from '../../types';
import { Calendar } from '@styled-icons/boxicons-regular/Calendar';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { MessageDetail } from '@styled-icons/boxicons-regular/MessageDetail';
import WorkflowDetailModal from './WorkflowDetailModal';
import StatusIcon from '../Shared/StatusIcon';
import CopyButton from '../Shared/CopyButton';
import { formatDistanceToNow } from 'date-fns';

interface WorkflowCardProps {
  workflowDetail: WorkflowDetail;
  showWorkflowName?: boolean;
  onClose?: () => void;
  isSingleColumn?: boolean;
}

/**
 * WorkflowCard component displays details of a workflow including status, commit info, and time ago.
 * It also provides a modal to show more detailed information.
 */
const WorkflowCard: React.FC<WorkflowCardProps> = ({
  workflowDetail,
  showWorkflowName,
  onClose,
  isSingleColumn = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const shortSha = workflowDetail.sha.substring(0, 7);
  const timeAgo = formatDistanceToNow(new Date(workflowDetail.date), {
    addSuffix: true,
  });

  return (
    <>
      <div className={`mx-auto ${isSingleColumn ? 'max-w-md' : 'w-full'}`}>
        <div
          className={`bg-gradient-to-br from-slate-700 to-slate-900 rounded-lg shadow-lg overflow-hidden transition-all duration-100 cursor-pointer hover:shadow-xl hover:ring-2 hover:ring-blue-400 z-40 ${
            isSingleColumn ? 'transform scale-90' : ''
          }`}
          onClick={() => setModalOpen(true)}
        >
          <div className="p-4 relative">
            <div className="absolute top-2 right-2">
              <StatusIcon status={workflowDetail.status} size={24} />
            </div>
            {showWorkflowName && (
              <h3 className="text-lg font-semibold text-white mb-2 truncate pr-8">
                {workflowDetail.environment}
              </h3>
            )}
            <div className="space-y-3">
              <div className="flex items-center space-x-2 text-slate-300">
                <Calendar size="18" />
                <span className="text-sm">{timeAgo}</span>
              </div>
              <div className="flex items-center space-x-2">
                <GitCommit size="18" className="text-blue-400 flex-shrink-0" />
                <p
                  className="text-white font-mono text-sm truncate flex-grow"
                  title={workflowDetail.sha}
                >
                  {shortSha}
                </p>
                <div className="relative" onClick={(e) => e.stopPropagation()}>
                  <CopyButton text={workflowDetail.sha} />
                </div>
              </div>
              <div className="flex items-start space-x-2">
                <MessageDetail
                  size="18"
                  className="text-blue-400 flex-shrink-0 mt-1"
                />
                <p
                  className="text-slate-300 text-sm truncate"
                  title={workflowDetail.commit}
                >
                  {workflowDetail.commit}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <WorkflowDetailModal
          workflowDetail={workflowDetail}
          onClose={() => {
            setModalOpen(false);
            onClose && onClose();
          }}
        />
      )}
    </>
  );
};

export default WorkflowCard;
