import { useQuery } from 'react-query';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

/**
 * Custom hook to fetch the GitHub repository URL.
 * Handles token expiration and refresh if needed.
 */
const useFetchRepoURL = () => {
  const { checkTokenExpiration, refreshAccessToken, login } = useAuth();

  const fetchRepoURL = async () => {
    try {
      if (checkTokenExpiration()) {
        await refreshAccessToken();
      }

      const response = await axiosInstance.get('/repo-url');

      const newAccessToken = response.headers['new-access-token'];
      const newRefreshToken = response.headers['new-refresh-token'];
      if (newAccessToken && newRefreshToken) {
        login(newAccessToken, newRefreshToken);
      }

      return response.data.repo_url;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new Error('Authentication failed. Please log in again.');
        } else if (error.response?.status === 404) {
          throw new Error('Repository URL not found.');
        } else {
          throw new Error('Failed to fetch repository URL. Please try again.');
        }
      } else {
        throw new Error('An unexpected error occurred.');
      }
    }
  };

  return fetchRepoURL;
};

/**
 * Custom hook to query the GitHub repository URL using React Query.
 */
export const useRepoURL = () => {
  const fetchRepoURL = useFetchRepoURL();
  return useQuery('repoURL', fetchRepoURL);
};
