import React from 'react';
import { XCircle } from '@styled-icons/foundation/XCircle';
import { Pending } from '@styled-icons/material-outlined/Pending';
import Tooltip from '../Tooltip';

interface StatusIconProps {
  status: string;
  size?: number;
}

/**
 * StatusIcon component renders different icons based on the status prop.
 */
const StatusIcon: React.FC<StatusIconProps> = ({ status, size = 16 }) => {
  const outerCircleSize = `${Math.round(size * 0.6)}px`; // Reduce outer circle to 75% of the overall size
  const innerIconSize = `${Math.round(size * 0.3)}px`; // Keep inner icon at 50% of the overall size

  const getStatusDetails = () => {
    switch (status.toLowerCase()) {
      case 'completed':
      case 'success':
        return {
          icon: (
            <div
              className="rounded-full bg-green-500"
              style={{ width: innerIconSize, height: innerIconSize }}
            />
          ),
          tooltip: 'Success',
          bgColor: 'bg-green-500',
        };
      case 'error':
      case 'failure':
        return {
          icon: <XCircle size={innerIconSize} className="text-red-500" />,
          tooltip: 'Error',
          bgColor: 'bg-red-500',
        };
      case 'canceled':
        return {
          icon: <XCircle size={innerIconSize} className="text-yellow-500" />,
          tooltip: 'Canceled',
          bgColor: 'bg-yellow-500',
        };
      case 'running':
        return {
          icon: <Pending size={innerIconSize} className="text-blue-500" />,
          tooltip: 'Running',
          bgColor: 'bg-blue-500',
        };
      default:
        return {
          icon: <Pending size={innerIconSize} className="text-gray-500" />,
          tooltip: 'Unknown',
          bgColor: 'bg-gray-500',
        };
    }
  };

  const { icon, tooltip, bgColor } = getStatusDetails();

  return (
    <Tooltip text={tooltip}>
      <div
        className="relative inline-flex items-center justify-center"
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <div
          className={`absolute ${bgColor} rounded-full opacity-20 animate-pulse`}
          style={{ width: outerCircleSize, height: outerCircleSize }}
        ></div>
        <div className="relative z-10 flex items-center justify-center">
          {icon}
        </div>
      </div>
    </Tooltip>
  );
};

export default StatusIcon;
