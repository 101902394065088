import { useQuery, UseQueryResult } from 'react-query';
import axiosInstance from '../api/axiosInstance';
import axios, { AxiosError } from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface UserDetails {
  username: string;
  avatarUrl: string;
}

/**
 * Custom hook to fetch and return user details using React Query.
 */
export const useUserDetails = (): UseQueryResult<UserDetails, AxiosError> => {
  const { checkTokenExpiration, refreshAccessToken, login } = useAuth();

  /**
   * Fetches user details from the API.
   */
  const fetchUserDetails = async (): Promise<UserDetails> => {
    try {
      if (checkTokenExpiration()) {
        await refreshAccessToken();
      }

      const response = await axiosInstance.get('/user');

      // Handle new access and refresh tokens if provided in the response headers
      const newAccessToken = response.headers['new-access-token'];
      const newRefreshToken = response.headers['new-refresh-token'];
      if (newAccessToken && newRefreshToken) {
        login(newAccessToken, newRefreshToken);
      }

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new Error('Authentication failed. Please log in again.');
        } else if (error.response?.status === 403) {
          throw new Error(
            "We've hit GitHub's rate limit. Please try again in a few minutes."
          );
        } else {
          throw new Error(
            'Failed to fetch user details. Please try again later.'
          );
        }
      } else {
        throw new Error('An unexpected error occurred. Please try again.');
      }
    }
  };

  return useQuery<UserDetails, AxiosError>('userDetails', fetchUserDetails, {
    staleTime: 5 * 60 * 1000, // Data is considered fresh for 5 minutes
    cacheTime: 10 * 60 * 1000, // Cache data for 10 minutes after it becomes stale
    refetchOnWindowFocus: false, // Do not refetch on window focus
    refetchOnReconnect: false, // Do not refetch on reconnect
    refetchOnMount: false, // Do not refetch on component mount
    retry: false, // Do not retry failed requests
    onError: (error: AxiosError) => {
      // Handle errors by displaying a toast message
      if (error.response?.status === 403) {
        toast.error(
          "We've hit GitHub's rate limit. Please try again in a few minutes."
        );
      } else {
        toast.error('Failed to fetch user details. Please try again later.');
      }
    },
  });
};
