/**
 * Utility function to copy text to the clipboard.
 *
 */
const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).catch(() => {
    alert('Failed to copy text to clipboard.');
  });
};

export default copyToClipboard;
