import React, { useState, useMemo } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { WorkflowDetail, Environment } from '../../types';
import EnvironmentActions from './EnvironmentActions';
import ConfirmationModal from '../ConfirmationModal';
import EnvironmentHistory from './EnvironmentHistory';
import WorkflowCard from '../Workflow/WorkflowCard';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { useRepoURL } from '../../hooks/useRepoURL';

interface EnvironmentDetailsProps {
  environment: Environment;
  workflowDetail: WorkflowDetail | null;
  history: WorkflowDetail[];
  workflowName: string;
}

/**
 * EnvironmentDetails component displays details of an environment including its workflows and history.
 */
const EnvironmentDetails: React.FC<EnvironmentDetailsProps> = ({
  environment,
  workflowDetail,
  history,
  workflowName,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isRunning, setIsRunning] = useState(false);
  const [runSuccess, setRunSuccess] = useState<boolean | null>(null);
  const { data: repoUrl, isLoading: isRepoUrlLoading } = useRepoURL();

  /**
   * Handles the action to run a workflow, showing a confirmation modal.
   */
  const handleRunWorkflow = () => {
    setShowConfirmation(true);
    setRunSuccess(null);
  };

  /**
   * Handles the action to view the history of a workflow.
   */
  const handleViewHistory = () => setShowHistory(true);

  /**
   * Runs the workflow by sending a POST request to the API.
   */
  const runWorkflow = async () => {
    setIsRunning(true);
    try {
      const encodedWorkflowName = encodeURIComponent(workflowName);
      const encodedEnvironment = encodeURIComponent(environment);
      await axiosInstance.post(
        `/run/${encodedEnvironment}/${encodedWorkflowName}`
      );
      setErrorMessage(null);
      setRunSuccess(true);
    } catch (error) {
      setErrorMessage('Failed to run workflow. Please try again.');
      setRunSuccess(false);
    } finally {
      setIsRunning(false);
      // Reset the success state after 3 seconds
      setTimeout(() => setRunSuccess(null), 3000);
    }
  };

  // Get the latest workflow detail
  const latestWorkflowDetail = useMemo(() => {
    if (history.length > 0) {
      const sortedHistory = [...history].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      return sortedHistory[0];
    }
    return workflowDetail;
  }, [history, workflowDetail]);

  return (
    <div className="bg-gradient-to-br from-slate-800 to-slate-900 rounded-lg shadow-xl overflow-hidden transition-all duration-300 hover:shadow-2xl">
      <div className="bg-gradient-to-r from-slate-700 to-slate-800 p-4">
        <div className="flex flex-wrap items-center justify-between gap-4">
          <h2 className="text-2xl font-bold text-white">{environment}</h2>
          {!isRepoUrlLoading && repoUrl && latestWorkflowDetail && (
            <div className="flex-shrink-0">
              <EnvironmentActions
                environment={environment}
                handleRunWorkflow={handleRunWorkflow}
                handleViewHistory={handleViewHistory}
                repoUrl={repoUrl}
                workflowName={workflowName}
                workflowFileName={latestWorkflowDetail.workflow_file_name}
                isRunning={isRunning}
                runSuccess={runSuccess}
              />
            </div>
          )}
        </div>
      </div>
      <div className="p-6">
        {errorMessage && (
          <div className="bg-red-500 bg-opacity-10 border border-red-500 rounded p-3 mb-4 text-red-300">
            {errorMessage}
          </div>
        )}
        {latestWorkflowDetail ? (
          <WorkflowCard workflowDetail={latestWorkflowDetail} />
        ) : (
          <div className="flex justify-center items-center h-32">
            <LoadingSpinner />
          </div>
        )}
      </div>
      {showConfirmation && (
        <ConfirmationModal
          message="Are you sure you want to run this workflow?"
          onConfirm={() => {
            runWorkflow();
            setShowConfirmation(false);
          }}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
      {showHistory && (
        <EnvironmentHistory
          history={history}
          toggleHistory={() => setShowHistory(false)}
          environment={environment}
          workflowName={workflowName}
        />
      )}
    </div>
  );
};

export default EnvironmentDetails;
