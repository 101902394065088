import React, { useMemo, useState, useEffect } from 'react';
import { WorkflowDetail } from '../../types';
import WorkflowCard from '../Workflow/WorkflowCard';
import { Close } from '@styled-icons/ionicons-solid/Close';
import { ChevronLeft, ChevronRight } from '@styled-icons/boxicons-regular';

interface EnvironmentHistoryProps {
  history: WorkflowDetail[];
  toggleHistory: () => void;
  environment: string;
  workflowName: string; // Add this line
}

const ITEMS_PER_PAGE = 5;

const EnvironmentHistory: React.FC<EnvironmentHistoryProps> = ({
  history,
  toggleHistory,
  environment,
  workflowName, // Add this prop
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const sortedHistory = useMemo(
    () =>
      [...history].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      ),
    [history]
  );

  const totalPages = Math.ceil(sortedHistory.length / ITEMS_PER_PAGE);

  const displayedHistory = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return sortedHistory.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [currentPage, sortedHistory]);

  useEffect(() => {}, [history, currentPage, displayedHistory]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="fixed inset-0 z-50 overflow-hidden bg-black bg-opacity-50 flex justify-center items-center backdrop-blur-sm">
      <div className="relative w-full h-full flex items-center justify-center">
        <button
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
          className="absolute left-0 w-1/4 h-full flex items-center justify-center transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed group"
          title="Previous Page"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-slate-800 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          <ChevronLeft
            size={96}
            className="text-white transition-transform duration-300 group-hover:scale-110 relative z-10"
          />
        </button>

        <div className="bg-slate-800 shadow-lg overflow-hidden flex flex-col max-h-[95vh] w-[800px]">
          <div className="bg-gradient-to-r from-slate-700 to-slate-800 p-4 flex justify-between items-center">
            <h2 className="text-2xl font-bold text-white">
              {environment} {workflowName} History
            </h2>
            <div className="flex items-center space-x-4">
              <span className="text-slate-300">
                Page {currentPage} of {totalPages}
              </span>
              <button
                className="text-slate-300 hover:text-white transition duration-300"
                onClick={toggleHistory}
                title="Close History"
              >
                <Close size="32" />
              </button>
            </div>
          </div>
          <div className="p-4 space-y-4 overflow-y-auto flex-grow">
            {displayedHistory.map((detail, index) => (
              <WorkflowCard
                key={`${detail.sha}-${index}`}
                workflowDetail={detail}
                showWorkflowName={false}
              />
            ))}
          </div>
          {totalPages > 1 && (
            <div className="bg-slate-700 p-4 flex justify-center items-center space-x-2">
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`w-3 h-3 rounded-full transition-all duration-300 ${
                    currentPage === index + 1
                      ? 'bg-blue-500 scale-125'
                      : 'bg-slate-500 hover:bg-slate-400'
                  }`}
                  title={`Page ${index + 1}`}
                />
              ))}
            </div>
          )}
        </div>

        <button
          onClick={() =>
            handlePageChange(Math.min(totalPages, currentPage + 1))
          }
          disabled={currentPage === totalPages}
          className="absolute right-0 w-1/4 h-full flex items-center justify-center transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed group"
          title="Next Page"
        >
          <div className="absolute inset-0 bg-gradient-to-l from-slate-800 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          <ChevronRight
            size={96}
            className="text-white transition-transform duration-300 group-hover:scale-110 relative z-10"
          />
        </button>
      </div>
    </div>
  );
};

export default EnvironmentHistory;
