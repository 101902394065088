import React from 'react';
import { WorkflowDetail } from '../../types';
import { ExternalLinkOutline } from '@styled-icons/evaicons-outline/ExternalLinkOutline';
import { Close } from '@styled-icons/ionicons-solid/Close';
import { CircleInfo } from '@styled-icons/fa-solid/CircleInfo';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { MessageDetail } from '@styled-icons/boxicons-regular/MessageDetail';
import { Status } from '@styled-icons/fluentui-system-regular/Status';
import { Person } from '@styled-icons/bootstrap/Person';
import { Calendar } from '@styled-icons/boxicons-regular/Calendar';
import { Time } from '@styled-icons/boxicons-regular/Time';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { Event } from '@styled-icons/material/Event';
import { Github } from '@styled-icons/boxicons-logos/Github';
import StatusIcon from '../Shared/StatusIcon';
import CopyButton from '../Shared/CopyButton';

interface WorkflowDetailModalProps {
  workflowDetail: WorkflowDetail;
  onClose: () => void;
}

// Wrapper component for icons
const IconWrapper: React.FC<{
  icon: React.ComponentType<any>;
  className?: string;
}> = ({ icon: Icon, className }) => (
  <div className={className}>
    <Icon />
  </div>
);

/**
 * WorkflowDetailModal component displays detailed information about a workflow in a modal.
 */
const WorkflowDetailModal: React.FC<WorkflowDetailModalProps> = ({
  workflowDetail,
  onClose,
}) => {
  const dateObj = new Date(workflowDetail.date);
  const date = dateObj.toLocaleDateString();
  const time = dateObj.toLocaleTimeString();
  const shortSha = workflowDetail.sha.substring(0, 7);

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const DetailItem: React.FC<{
    icon: React.ComponentType<any>;
    title: string;
    value: React.ReactNode;
  }> = ({ icon, title, value }) => (
    <div className="flex items-start space-x-3">
      <IconWrapper
        icon={icon}
        className="text-blue-400 flex-shrink-0 mt-1 w-5 h-5"
      />
      <div className="flex-1 min-w-0">
        <p className="font-semibold text-slate-300">{title}</p>
        <div className="text-white break-words">{value}</div>
      </div>
    </div>
  );

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-gradient-to-br from-slate-800 to-slate-900 rounded-lg shadow-2xl w-11/12 md:w-2/3 lg:w-1/2 max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-6 space-y-6">
          <div className="flex justify-between items-center border-b border-slate-700 pb-4">
            <div className="flex items-center space-x-3">
              <IconWrapper
                icon={CircleInfo}
                className="text-blue-400 w-6 h-6"
              />
              <h2 className="text-2xl font-bold text-white">
                {workflowDetail.environment} Details
              </h2>
            </div>
            <button
              onClick={onClose}
              className="text-slate-400 hover:text-white transition duration-300"
            >
              <IconWrapper icon={Close} className="w-6 h-6" />
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <DetailItem
              icon={GitCommit}
              title="SHA"
              value={
                <div className="flex items-center space-x-2">
                  <span className="font-mono">{shortSha}</span>
                  <CopyButton text={workflowDetail.sha} />
                </div>
              }
            />
            <DetailItem
              icon={MessageDetail}
              title="Message"
              value={workflowDetail.commit}
            />
            <DetailItem
              icon={Status}
              title="Status"
              value={
                <div className="flex items-center space-x-2">
                  <span>{capitalizeFirstLetter(workflowDetail.status)}</span>
                  <StatusIcon status={workflowDetail.status} />
                </div>
              }
            />
            <DetailItem
              icon={Person}
              title="Author"
              value={workflowDetail.author}
            />
            <DetailItem icon={Calendar} title="Date" value={date} />
            <DetailItem icon={Time} title="Time" value={time} />
            <DetailItem
              icon={CheckCircle}
              title="Conclusion"
              value={workflowDetail.conclusion || 'N/A'}
            />
            <DetailItem
              icon={Event}
              title="Event"
              value={workflowDetail.event || 'N/A'}
            />
          </div>

          <div className="flex justify-center pt-4 border-t border-slate-700">
            <a
              href={workflowDetail.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-blue-400 hover:text-blue-300 transition duration-300 bg-slate-700 hover:bg-slate-600 px-4 py-2 rounded-full"
            >
              <IconWrapper icon={Github} className="mr-2 w-5 h-7" />
              <span>View in GitHub</span>
              <IconWrapper
                icon={ExternalLinkOutline}
                className="ml-2 w-4 h-6.5"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowDetailModal;
