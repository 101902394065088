import { useQuery } from 'react-query';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { useState } from 'react';

interface Activity {
  user: string;
  user_avatar_url: string;
  api: string;
  environment: string;
  commit: string;
  status: string;
  duration: string;
  deployed_at: string;
  duration_secs: number;
  avg_change: number;
}

/**
 * Fetches the latest activities, handling token expiration and refresh.
 */
const useFetchLatestActivities = () => {
  const { checkTokenExpiration, refreshAccessToken, login } = useAuth();

  const fetchLatestActivities = async () => {
    try {
      if (checkTokenExpiration()) {
        await refreshAccessToken();
      }

      const response = await axiosInstance.get('/latest-activity');

      const newAccessToken = response.headers['new-access-token'];
      const newRefreshToken = response.headers['new-refresh-token'];
      if (newAccessToken && newRefreshToken) {
        login(newAccessToken, newRefreshToken);
      }

      if (!Array.isArray(response.data)) {
        throw new Error('Unexpected response format');
      }

      return response.data
        .filter((activity: Activity) =>
          activity.api.startsWith('Deployment for')
        )
        .sort(
          (a: Activity, b: Activity) =>
            new Date(b.deployed_at).getTime() -
            new Date(a.deployed_at).getTime()
        );
      // Remove the .slice(0, 10) here
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new Error('Authentication failed. Please log in again.');
        } else if (error.response?.status === 404) {
          throw new Error('Activities not found.');
        } else {
          throw new Error(`Failed to fetch activities: ${error.message}`);
        }
      } else {
        throw new Error('An unexpected error occurred.');
      }
    }
  };

  return fetchLatestActivities;
};

/**
 * Retrieves the latest activity data using react-query.
 */
export const useLatestActivity = () => {
  const [page, setPage] = useState(1);
  const fetchLatestActivities = useFetchLatestActivities();

  const query = useQuery<Activity[], Error>(
    ['latestActivity'],
    fetchLatestActivities,
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000,
    }
  );

  const paginatedData = query.data
    ? query.data.slice((page - 1) * 10, page * 10)
    : [];

  return {
    ...query,
    page,
    setPage,
    paginatedData,
    totalPages: query.data ? Math.ceil(query.data.length / 10) : 0,
  };
};
