import { useQuery } from 'react-query';
import axiosInstance from '../api/axiosInstance';
import { WorkflowDetail, Environment } from '../types';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

const environments: Environment[] = ['Dev', 'Staging', 'Prod'];

/**
 * Fetches workflow details for a given workflow name.
 * Handles token expiration and refresh if needed.
 */
const useFetchWorkflowDetails = () => {
  const { checkTokenExpiration, refreshAccessToken } = useAuth();

  const fetchWorkflowDetails = async (workflowName: string) => {
    try {
      if (checkTokenExpiration()) {
        await refreshAccessToken();
      }

      const responses = await Promise.all(
        environments.map((env) => {
          const encodedName = encodeURIComponent(workflowName);
          return axiosInstance
            .get(`/workflow/${env}/${encodedName}/status`)
            .catch((error) => {
              if (error.response && error.response.status === 404) {
                return { data: { workflow_runs: [] } };
              }
              throw error;
            });
        })
      );

      const newDetails = responses.reduce((acc, response, index) => {
        const env = environments[index];
        const runs = response.data.workflow_runs;
        if (runs.length > 0) {
          acc[env] = {
            ...runs[0],
            workflow_file_name: runs[0].workflow_file_name,
          };
        }
        return acc;
      }, {} as Record<Environment, WorkflowDetail | null>);

      const historyDetails = responses.flatMap((response, index) =>
        response.data.workflow_runs.map((run: any) => ({
          environment: environments[index],
          status: run.status,
          sha: run.sha,
          commit: run.commit,
          author: run.author,
          date: run.date,
          url: run.url,
          conclusion: run.conclusion,
          event: run.event,
          duration: run.duration,
          workflow_file_name: run.workflow_file_name,
        }))
      );

      // Sort history details by date, most recent first
      historyDetails.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );

      return { latestDetails: newDetails, workflowHistory: historyDetails };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 401:
            throw new Error('Authentication failed. Please log in again.');
          case 403:
            throw new Error('Access forbidden. Please check your permissions.');
          default:
            throw new Error(
              'Failed to fetch workflow details. Please try again later.'
            );
        }
      } else {
        throw new Error('An unexpected error occurred. Please try again.');
      }
    }
  };

  return fetchWorkflowDetails;
};

/**
 * Custom hook to fetch and return the latest details and history of workflows.
 * It fetches data only when a workflow is selected.
 */
export const useWorkflowData = (selectedWorkflow: string | null) => {
  const fetchWorkflowDetails = useFetchWorkflowDetails();
  return useQuery(
    ['workflowDetails', selectedWorkflow],
    () => fetchWorkflowDetails(selectedWorkflow || ''),
    {
      enabled: !!selectedWorkflow,
      refetchInterval: 30000, // Refetch every 30 seconds
      staleTime: 25000, // Consider data stale after 25 seconds
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};
