import React from 'react';

interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

/**
 * Tooltip component displays a text tooltip when the user hovers over the child element.
 */
const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  return (
    <div className="relative flex items-center group">
      {children}
      <div className="absolute left-full ml-2 w-34 bg-gray-500 text-white text-sm rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-100 pointer-events-none z-40">
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
