import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Copy } from '@styled-icons/boxicons-regular/Copy';
import { Check } from '@styled-icons/material-rounded/Check';
import copyToClipboard from '../../utils/copyToClipboard';

interface CopyButtonProps {
  text: string;
}

/**
 * CopyButton component allows copying text to clipboard with visual feedback.
 */
const CopyButton: React.FC<CopyButtonProps> = ({ text }) => {
  const [copied, setCopied] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleCopy = (e: React.MouseEvent) => {
    e.stopPropagation();
    copyToClipboard(text);
    setCopied(true);
    const rect = (e.target as HTMLElement).getBoundingClientRect();
    setTooltipPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    });
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <>
      <button
        onClick={handleCopy}
        className="relative text-white hover:text-blue-300 transition duration-300"
        title="Copy to clipboard"
      >
        {copied ? <Check size="18" /> : <Copy size="18" />}
      </button>
      {copied &&
        tooltipPosition &&
        createPortal(
          <span
            className="absolute text-sm text-white bg-gray-700 rounded-md px-2 py-1 shadow-lg z-50"
            style={{
              top: tooltipPosition.top + 20,
              left: tooltipPosition.left,
              whiteSpace: 'nowrap',
            }}
          >
            Copied!
          </span>,
          document.body
        )}
    </>
  );
};

export default CopyButton;
